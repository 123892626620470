@mixin mobile(){
    @media(width <= 480px){
        @content;
    }
}
@mixin tablet(){
    @media(width <= 768px){
        @content;
    }
}

@mixin laptop(){
    @media(width <= 1024px){
        @content;
    }
}

@mixin extraLargeScreen(){
    @media(width > 1200px){
        @content;
    }
}